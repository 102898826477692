var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "d-flex align-center justify-center",
      staticStyle: { "min-height": "100vh" },
    },
    [
      _vm.redirecting
        ? _c(
            "v-overlay",
            { attrs: { opacity: "0.7" } },
            [
              _c("LoganLoading", [
                _c("div", { staticClass: "mt-5" }, [
                  _vm._v("Redirecting to app..."),
                ]),
              ]),
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "bg" },
        [
          _vm.fullAppDenied ||
          _vm.noCasinos ||
          _vm.noGames ||
          _vm.noStudios ||
          _vm.redirecting ||
          _vm.refreshing
            ? _c(
                "v-card",
                {
                  staticClass: "op ma-12 pa-4",
                  attrs: { width: "500px", dark: "" },
                },
                [
                  _c("v-card-title", { staticClass: "text-h1" }, [
                    _vm._v("401"),
                  ]),
                  _c("v-card-subtitle", { staticClass: "text-h5 pa-8" }, [
                    _vm._v(
                      " You do not have the " +
                        _vm._s(
                          _vm.fullAppDenied ||
                            _vm.noCasinos ||
                            _vm.noGames ||
                            _vm.noStudios
                            ? `groups`
                            : ""
                        ) +
                        " required to access this app. "
                    ),
                    _c("br"),
                    _vm._v(" Contact your admin for more info. "),
                  ]),
                  _c("v-card-text"),
                  _c(
                    "v-card-actions",
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "primary",
                          attrs: {
                            disabled: _vm.refreshing,
                            loading: _vm.refreshing,
                            "x-large": "",
                          },
                          on: { click: _vm.refresh },
                        },
                        [_vm._v(" Refresh ")]
                      ),
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          staticClass: "primary",
                          attrs: { "x-large": "" },
                          on: { click: _vm.logout },
                        },
                        [_vm._v(" Logout ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _c(
                "v-card",
                {
                  staticClass: "op ma-12 pa-4",
                  attrs: { width: "500px", dark: "" },
                },
                [
                  _c("v-card-title", { staticClass: "text-h1" }, [
                    _vm._v("401"),
                  ]),
                  _c("v-card-subtitle", { staticClass: "text-h5 pa-8" }, [
                    _c("span", [_vm._v("Unauthorized access")]),
                  ]),
                  _c(
                    "v-card-actions",
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          staticClass: "primary",
                          attrs: { to: "/", "x-large": "" },
                        },
                        [_vm._v(" Home ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }