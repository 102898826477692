<template>
	<div
		class="d-flex align-center justify-center"
		style="min-height: 100vh">
		<v-overlay
			v-if="redirecting"
			opacity="0.7">
			<LoganLoading>
				<div class="mt-5">Redirecting to app...</div>
			</LoganLoading>
		</v-overlay>
		<div class="bg">
			<v-card
				v-if="
					fullAppDenied ||
					noCasinos ||
					noGames ||
					noStudios ||
					redirecting ||
					refreshing
				"
				width="500px"
				class="op ma-12 pa-4"
				dark>
				<v-card-title class="text-h1">401</v-card-title>
				<v-card-subtitle class="text-h5 pa-8">
					You do not have the
					{{
						fullAppDenied || noCasinos || noGames || noStudios ? `groups` : ""
					}}
					required to access this app.
					<br />
					Contact your admin for more info.
				</v-card-subtitle>
				<v-card-text></v-card-text>
				<v-card-actions>
					<v-btn
						class="primary"
						:disabled="refreshing"
						:loading="refreshing"
						@click="refresh"
						x-large>
						Refresh
					</v-btn>
					<v-spacer />
					<v-btn
						class="primary"
						@click="logout"
						x-large>
						Logout
					</v-btn>
				</v-card-actions>
			</v-card>
			<v-card
				v-else
				width="500px"
				class="op ma-12 pa-4"
				dark>
				<v-card-title class="text-h1">401</v-card-title>
				<v-card-subtitle class="text-h5 pa-8">
					<span>Unauthorized access</span>
				</v-card-subtitle>
				<v-card-actions>
					<v-spacer />
					<v-btn
						class="primary"
						to="/"
						x-large>
						Home
					</v-btn>
				</v-card-actions>
			</v-card>
		</div>
	</div>
</template>

<script>
import permissions from "../../mixins/permissions";
import { mapActions } from "vuex";
import LoganLoading from "../LoganLoading.vue";
import _ from "lodash";

export default {
	mixins: [permissions],
	components: {
		LoganLoading,
	},
	data() {
		return {
			refreshing: false,
			redirecting: false,
		};
	},
	computed: {
		noCasinos() {
			return this.$store.getters.allowedCasinos?.length == 0;
		},
		noGames() {
			return this.$store.getters["meta2/allowedGames"]?.length == 0;
		},
		noStudios() {
			return this.$store.getters.allowedStudios?.length == 0;
		},
		fullAppDenied() {
			return (
				!this.$store.getters.userPerm ||
				Object.keys(this.$store.getters.userPerm).length == 0 ||
				!this._usrFlagsSome({
					key: "meta_games.list",
					val: this.permissions.READ,
				}) ||
				!this._usrFlagsSome({
					key: "meta_casinos.list",
					val: this.permissions.READ,
				})
			);
		},
	},
	methods: {
		...mapActions("apiCall", {
			refreshTokens: "refreshTokens",
			checkExpiry: "checkTokenExpiry",
		}),
		async refresh() {
			this.redirecting = false;
			this.refreshing = true;
			await this.refreshTokens();
			setTimeout(async () => {
				if (
					!this.noCasinos &&
					!this.noGames &&
					!this.noStudios &&
					!this.fullAppDenied
				) {
					this.redirecting = true;
					await this.checkExpiry(true);
					this.$router.push("/");
				}
				this.refreshing = false;
			}, 1000);
		},
		logout() {
			this.$store.dispatch("logout");
		},
	},
};
</script>

<style>
.bg {
	background-size: cover;
	background-position: center;
	height: 100%;
}

.op {
	background-color: rgb(0, 0, 0, 0.6) !important;
	border-color: transparent !important;
}
</style>
